"use client";
import {
  Label,
  Pie,
  PieChart,
  Bar,
  BarChart,
  CartesianGrid,
  XAxis,
  Sector,
} from "recharts";
import { PieSectorDataItem } from "recharts/types/polar/Pie";
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
  ChartLegend,
  ChartLegendContent,
} from "../../components/ui/chart";

const chartConfig = {
  // visitors: {
  //     label: "Visitors",
  //   },
  value: {
    label: "Land Value ₹",
    color: "hsl(var(--chart-2))",
  },
  prev: {
    label: "Original",
    color: "hsl(var(--primary))",
  },
  price: {
    label: "Inflation",
    color: "hsl(var(--chart-2))",
  },
} satisfies ChartConfig;

import { useEffect, useState } from "react";
import { indianFormat } from "../../lib/helper";

type Data = {
  plotInvestment: number;
  seasonalArea: number;
  timberArea: number;
  farmHouse: number;
};

type BarData = {
  growthRate: string;
  value: string;
  year: string;
}[];

function Charts({ className }: { className?: string }) {
  const [data, setData] = useState<Data>({
    plotInvestment: 100,
    seasonalArea: 40,
    timberArea: 20,
    farmHouse: 20,
  });

  const [barData, setBarData] = useState<BarData>([
    { growthRate: "6.00", value: "263965236", year: "2025" },
    { growthRate: "14.36", value: "279803150", year: "2026" },
    { growthRate: "25.10", value: "296591339", year: "2027" },
    { growthRate: "38.25", value: "314386819", year: "2028" },
    { growthRate: "46.82", value: "333250028", year: "2029" },
  ]);
  const [isPie, setIsPie] = useState<boolean>(false);

  useEffect(() => {
    if (isPie) {
      setTimeout(() => {
        setIsPie(!isPie);
        setBarData([
          { growthRate: "6.00", value: "263965236", year: "2025" },
          { growthRate: "14.36", value: "279803150", year: "2026" },
          { growthRate: "25.10", value: "296591339", year: "2027" },
          { growthRate: "38.25", value: "314386819", year: "2028" },
          { growthRate: "46.82", value: "333250028", year: "2029" },
        ]);
      }, 2000);
    } else {
      setTimeout(() => {
        setIsPie(!isPie);
        setData({
          plotInvestment: 100,
          seasonalArea: 40,
          timberArea: 20,
          farmHouse: 20,
        });
      }, 2000);
    }
  }, [isPie]);

  return (
    <div className={`${className} w-full h-full"`}>
      {isPie ? (
        <ChartContainer config={chartConfig} className="mt-5">
          <BarChart accessibilityLayer data={barData}>
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="year"
              tickLine={false}
              tickMargin={10}
              axisLine={false}
              tickFormatter={(value) => value}
            />
            <ChartTooltip content={<ChartTooltipContent hideLabel />} />
            <ChartLegend content={<ChartLegendContent />} />
            <Bar
              dataKey="value"
              stackId="a"
              fill="var(--color-value)"
              radius={[4, 4, 0, 0]}
            />
          </BarChart>
        </ChartContainer>
      ) : (
        <ChartContainer
          config={{}}
          className={`mx-auto aspect-square max-h-[250px]`}
        >
          <PieChart>
            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent hideLabel />}
            />
            <Pie
              data={[
                {
                  name: "free",
                  area:
                    Number(data?.plotInvestment) -
                    Number(data?.seasonalArea) -
                    Number(data?.timberArea),
                  fill: "#41B3A2",
                },
                {
                  name: "Sesonal",
                  area: data?.seasonalArea,
                  fill: "#3795BD",
                },
                {
                  name: "Timber",
                  area: Number(data?.timberArea),
                  fill: "#FF9100",
                },
                {
                  name: "Farm House",
                  area: Number(data?.farmHouse),
                  fill: "#C8A1E0",
                },
              ]}
              activeShape={({
                outerRadius = 0,
                ...props
              }: PieSectorDataItem) => (
                <Sector {...props} outerRadius={outerRadius + 10} />
              )}
              dataKey="area"
              nameKey="name"
              innerRadius={60}
              strokeWidth={5}
              activeIndex={0}
            >
              <Label
                content={({ viewBox }) => {
                  if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                    return (
                      <text
                        x={viewBox.cx}
                        y={viewBox.cy}
                        textAnchor="middle"
                        dominantBaseline="middle"
                      >
                        <tspan
                          x={viewBox.cx}
                          y={viewBox.cy}
                          className="fill-foreground text-sm font-bold"
                        >
                          Area
                        </tspan>
                        <tspan
                          x={viewBox.cx}
                          y={(viewBox.cy || 0) + 24}
                          className="text-xs fill-muted-foreground"
                        >
                          {`${indianFormat(
                            Number(data?.seasonalArea) +
                              Number(data?.timberArea)
                          )} Acre`}
                        </tspan>
                      </text>
                    );
                  }
                }}
              />
            </Pie>
          </PieChart>
        </ChartContainer>
      )}
    </div>
  );
}

export default Charts;

"use client";
// import router from "next/router";
import React, { useContext } from "react";
import HoverBorder from "../ui/HoverBtn";
import Link from "next/link";
import { AuthContext } from "../../providers/AuthProvider";
import { AuthContextType } from "../../providers/AuthProvider";

const NavBar = ({ isSignedIn }: { isSignedIn: boolean }) => {
  const { signOutUser } = useContext(AuthContext) as AuthContextType;
  return (
    <div className="p-2 bg-green-50/50 sticky top-0 z-50">
      <div className="flex justify-between items-center gap-4">
        <div className="w-40 font-semibold text-xl cursor-pointer bg-clip-text bg-no-repeat  text-transparent bg-gradient-to-r from-green-600 via-green-500 to-green-600 [text-shadow:0_0_rgba(0,0,0,0.1)] my-3">
          KnowMyFarm
        </div>
        {isSignedIn ? (
          <Link href="/sign-in" className="flex justify-end w-40">
            <HoverBorder
              containerClassName="rounded-full h-12 w-24"
              highlightColor="#FFF9D0"
              onClick={() => {
                signOutUser();
              }}
            >
              Sign out
            </HoverBorder>
          </Link>
        ) : (
          <Link href="/sign-in" className="flex justify-end w-40">
            <HoverBorder
              containerClassName="rounded-full h-12 w-24"
              highlightColor="#cdffcd"
              // onClick={() => router.push("/sign-in")}
            >
              Sign In
            </HoverBorder>
          </Link>
        )}
      </div>
    </div>
  );
};

export default NavBar;

"use client";

import { cn } from "../../lib/utils";
import { motion, MotionProps } from "framer-motion";
import React from "react";

interface AuroraTextProps
  extends Omit<React.HTMLAttributes<HTMLElement>, keyof MotionProps> {
  className?: string;
  children: React.ReactNode;
  as?: React.ElementType;
  mod?: "color-1" | "color-2";
}

export function AuroraText({
  className,
  children,
  mod = "color-1",
  as: Component = "span",
  ...props
}: AuroraTextProps) {
  const MotionComponent = motion.create(Component);

  return (
    <MotionComponent
      className={cn("relative inline-flex overflow-hidden w-max", className)}
      {...props}
    >
      {children}

      {mod === "color-1" ? (
        <span className="pointer-events-none absolute inset-0 mix-blend-lighten dark:mix-blend-darken rounded-lg px-2">
          <span className="pointer-events-none absolute -top-1/2 h-[30vw] w-[30vw] animate-[aurora-border_6s_ease-in-out_infinite,aurora-1_9s_ease-in-out_infinite_alternate] bg-[hsl(var(--color-2))] mix-blend-overlay blur-[1rem]"></span>
          <span className="pointer-events-none absolute right-0 top-0 h-[30vw] w-[30vw] animate-[aurora-border_6s_ease-in-out_infinite,aurora-2_9s_ease-in-out_infinite_alternate] bg-[hsl(var(--color-3))] mix-blend-overlay blur-[1rem]"></span>
          <span className="pointer-events-none absolute bottom-0 left-0 h-[30vw] w-[30vw] animate-[aurora-border_6s_ease-in-out_infinite,aurora-3_9s_ease-in-out_infinite_alternate] bg-[hsl(var(--color-3))] mix-blend-overlay blur-[1rem]"></span>
          <span className="pointer-events-none absolute -bottom-1/2 right-0 h-[30vw] w-[30vw] animate-[aurora-border_6s_ease-in-out_infinite,aurora-4_9s_ease-in-out_infinite_alternate] bg-[hsl(var(--color-2))] mix-blend-overlay blur-[1rem]"></span>
        </span>
      ) : (
        <span className="pointer-events-none absolute inset-0 mix-blend-lighten dark:mix-blend-darken rounded-md px-2">
          <span className="pointer-events-none absolute -top-1/2 h-[20vw] w-[20vw] animate-[aurora-border_6s_ease-in-out_infinite,aurora-1_9s_ease-in-out_infinite_alternate] bg-[hsl(var(--color-5))] mix-blend-overlay blur-[1rem]"></span>
          <span className="pointer-events-none absolute right-0 top-0 h-[20vw] w-[20vw] animate-[aurora-border_6s_ease-in-out_infinite,aurora-2_9s_ease-in-out_infinite_alternate] bg-[hsl(var(--color-5))] mix-blend-overlay blur-[1rem]"></span>
          <span className="pointer-events-none absolute bottom-0 left-0 h-[20vw] w-[20vw] animate-[aurora-border_6s_ease-in-out_infinite,aurora-3_9s_ease-in-out_infinite_alternate] bg-[hsl(var(--color-5))] mix-blend-overlay blur-[1rem]"></span>
          <span className="pointer-events-none absolute -bottom-1/2 right-0 h-[20vw] w-[20vw] animate-[aurora-border_6s_ease-in-out_infinite,aurora-4_9s_ease-in-out_infinite_alternate] bg-[hsl(var(--color-5))] mix-blend-overlay blur-[1rem]"></span>
        </span>
      )}
    </MotionComponent>
  );
}

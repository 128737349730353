"use client";

import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

export interface AnimatedListProps {
  className?: string;
  children: React.ReactNode;
  delay?: number;
}

export const AnimatedList = React.memo(
  ({ className, children, delay = 1500 }: AnimatedListProps) => {
    const [index, setIndex] = useState(0);
    const [pause, setPause] = useState(false);
    const childrenArray = React.Children.toArray(children);

    useEffect(() => {
      if (!pause) {
        const interval = setInterval(() => {
          setIndex((prevIndex) => (prevIndex + 1) % childrenArray.length);
        }, delay);
        return () => clearInterval(interval);
      }
    }, [childrenArray.length, delay, pause]);

    const itemsToShow = useMemo(
      () => childrenArray.slice(0, index + 1).reverse(),
      [index, childrenArray]
    );

    return (
      <div className={`flex flex-col items-center gap-4 ${className}`}>
        <AnimatePresence>
          {itemsToShow.map((item: React.ReactNode) => (
            <AnimatedListItem
              key={(item as ReactElement).key}
              setPause={setPause}
              pause={pause}
            >
              {item}
            </AnimatedListItem>
          ))}
        </AnimatePresence>
      </div>
    );
  }
);

AnimatedList.displayName = "AnimatedList";

export function AnimatedListItem({
  children,
  pause,
  setPause,
}: {
  children: React.ReactNode;
  pause: boolean;
  setPause: (pause: boolean) => void;
}) {
  const animations = {
    initial: { scale: 0, opacity: 0 },
    animate: { scale: 1, opacity: 1, originY: 0 },
    exit: { scale: 0, opacity: 0 },
    transition: { type: "spring", stiffness: 350, damping: 40 },
  };

  return (
    <motion.div
      {...animations}
      layout
      className="px-0 md:px-5 w-full"
      onMouseOver={() => {
        if (!pause) {
          setPause(true);
        }
      }}
      onMouseLeave={() => {
        if (pause) {
          setPause(false);
        }
      }}
    >
      {children}
    </motion.div>
  );
}
